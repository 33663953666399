<template>
  <div>
    <SearchResults />
  </div>
</template>

<script>
import SearchResults from '@/components/Search/SearchResults.vue';

export default {
  name: 'Search',
  components: {
    SearchResults
  }
};
</script>

<style lang="scss" scoped></style>
